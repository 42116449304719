const MAX_DATE = 99999999999999;
const MAX_LENGTH_UUID = 60;

const getDefaultSchema = (params) => ({
  ...params,
  title: 'GeoNAM offline entity schema',
  version: 3,
  primaryKey: 'uuid',
  type: 'object',
  properties: {
    createdOn: {
      maximum: MAX_DATE,
      minimum: 0,
      multipleOf: 1,
      type: 'number',
    },
    draft: {
      type: ['object', 'null'],
    },
    entity: {
      type: 'object',
    },
    modifiedOn: {
      maximum: MAX_DATE,
      minimum: 0,
      multipleOf: 1,
      type: 'number',
    },
    jsonPatch: {
      type: ['array', 'null'],
    },
    offlineMap: {
      type: ['object', 'null'],
    },
    relations: {
      type: ['array', 'null'],
    },
    remoteVersion: {
      type: 'number',
    },
    userId: {
      type: ['number', 'null'],
    },
    uuid: {
      maxLength: MAX_LENGTH_UUID,
      type: 'string',
    },
  },
  attachments: {},
  required: ['createdOn', 'modifiedOn'],
  indexes: ['createdOn', 'modifiedOn'],
});

export default getDefaultSchema;
