import { getReference } from '@geomagic/geonam';

import checkSyncErrors from '@database/checkSyncErrors';
import getUpdatedDoc from '@database/getUpdatedDoc';
import handleFetchError from '@database/handleFetchError';
import handleSyncError from '@database/handleSyncError';
import resetDoc from '@database/resetDoc';

import getRemainingJsonPatch from './getRemainingJsonPatch';
import updateFormsAndElements from './updateFormsAndElements';
import updateRelevantDispatches from './updateRelevantDispatches';

const getAssignmentSync = async ({
  client,
  database,
  doc,
  entityClasses,
  mapProps,
  refetchQuery,
  selector,
  transform,
}) => {
  const syncErrors = [];
  const entityReference = getReference(doc?.entity);
  const idMap = new Map();

  try {
    const updateProps = {
      client,
      database,
      doc,
      entityClasses,
      entity: doc?.entity,
      idMap,
      mapProps,
      patchedEntity: doc.getPatchedEntity(),
      selector,
      syncErrors,
    };
    await updateFormsAndElements(updateProps);
    await updateRelevantDispatches(updateProps);
    const updatedEntity = refetchQuery && (await getUpdatedDoc(client, refetchQuery, entityReference, mapProps));

    if (updatedEntity) {
      const entity = transform && (await transform(database, updatedEntity));
      const remainingJsonPatch = getRemainingJsonPatch(doc, idMap);
      await resetDoc(doc, entity, remainingJsonPatch);
    }
  } catch (error) {
    console.error(error);

    handleFetchError({ error, isDefaultThrow: false });
    handleSyncError({ entityReference, error, syncErrors });
  }
  checkSyncErrors(syncErrors);
};

export default getAssignmentSync;
