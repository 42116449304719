import getDefaultSchema from '@database/getDefaultSchema';
import getDispatchSchema from '@database/getDispatchSchema';
import { DEFAULT_STATE_KEY } from '@consts';

const v1Migration = (oldDoc) => {
  oldDoc.relations = null;
  return oldDoc;
};

const v2Migration = (oldDoc) => {
  oldDoc.remoteVersion = 0;
  return oldDoc;
};

const v3Migration = (oldDoc) => {
  const globalState = window.localStorage.getItem(DEFAULT_STATE_KEY);
  const { user } = globalState ? JSON.parse(globalState) : {};
  oldDoc.userId = user ? user.id : null;

  return oldDoc;
};

const v4DispatchMigration = (oldDoc) => {
  oldDoc.isNearby = false;
  oldDoc.isWorkbench = true;
  return oldDoc;
};

const collections = {
  assignments: {
    schema: getDefaultSchema(),
    migrationStrategies: {
      1: v1Migration,
      2: v2Migration,
      3: v3Migration,
    },
  },
  dispatches: {
    schema: getDispatchSchema(),
    migrationStrategies: {
      1: v1Migration,
      2: v2Migration,
      3: v3Migration,
      4: v4DispatchMigration,
    },
  },
};

export default collections;
