import { applyPatch } from 'rfc6902';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';

const getPatchedEntity = (doc) => {
  const { entity, jsonPatch } = doc.getLatest();
  const patchedEntity = cloneDeep(entity);

  if (isArray(jsonPatch)) {
    applyPatch(patchedEntity, jsonPatch);
  }

  return patchedEntity;
};

export default getPatchedEntity;
