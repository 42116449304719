import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useApolloClient } from '@apollo/client';
import { useRxDB } from 'rxdb-hooks';
import { useSwipeable } from 'react-swipeable';

import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import { PREFERENCES_PREFIX, getStickyValue } from '@geomagic/nam-react-core/utils/useStickyState';

import AppBarProvider from '@components/AppBar/AppBarProvider';
import ConflictResolution, { getConflicts } from '@components/ConflictResolution';
import DispatchCreationMode from '@components/DispatchCreationMode';
import Map from '@components/Map';
import RouteContainer from '@components/RouteContainer';
import { CREATE_DISPATCHES_KEY } from '@consts';

import pull from '@database/pull';
import push from '@database/push';
import useSyncCount from '@database/useSyncCount';
import getFetchConfig from '@synchronization/getFetchConfig';
import getSyncConfig from '@synchronization/getSyncConfig';
import showModal from '@utils/showModal';
import shouldPrefetch from '@utils/shouldPrefetch';
import useLoadingSnackbar from '@utils/useLoadingSnackbar';

import PrefetchDataDialog from './PrefetchDataDialog';
import { OFFLINE_DOCUMENT_SYNC_KEY } from '../../consts';

const styles = {
  map: {
    borderLeft: `1px solid palette.divider`,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '55%',
  },
  mapFullscreen: {
    height: '100%',
    width: '100%',
  },
  mapHidden: {
    display: 'none',
    height: '45%',
    width: 0,
  },
  mapSplitView: {
    border: 'none',
    height: '45%',
    width: '100%',
  },
};

const useStyles = makeStyles()(({ palette, shape, spacing, transitions }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    container: {
      display: 'flex',
      flex: 1,
      position: 'relative',
      overflow: 'hidden',
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row-reverse',
      position: 'relative',
      overflow: 'hidden',
    },
    contentMobile: {
      marginBottom: 56,
    },
    contentSplitView: {
      flexDirection: 'row',
    },
    icon: {
      color: palette.text.primary,
      transform: 'scaleX(1.5) rotate(0deg)',
      transition: transitions.create('transform', { duration: transitions.duration.shortest }),
    },
    iconFlipped: {
      transform: 'scaleX(1.5) rotate(180deg)',
    },
    routes: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '45%',
      transition: transitions.create('height', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    routesSplitView: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      overflow: 'hidden',
      height: `calc(55% + ${shape.borderRadius * 2}px)`,
      backgroundColor: palette.background.paper,
      borderTop: '1px solid rgba(0, 0, 0, .125)',
      borderTopLeftRadius: shape.borderRadius * 2,
      borderTopRightRadius: shape.borderRadius * 2,
    },
    routesFullScreen: {
      borderRadius: 0,
      borderTop: 'none',
      height: '100%',
      width: '100%',
    },
    routesHidden: {
      display: 'none',
    },
    swipeableArea: {
      borderBottom: `1px solid ${palette.divider}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 36,
      cursor: 'pointer',
    },
    toolbar: {
      minHeight: 56,
    },
    trigger: {
      marginRight: spacing(),
    },
  };
});

const ViewContainer = (props) => {
  const { config, state } = props;
  const { entityClasses, isMobile, isOnline, lastFetch, lastLogin, preferences, user } = state;
  const {
    srid,
    view: mapView,
    systemInformation,
    updateGlobalState,
    vectorTileServerUrl,
    world,
    worlds,
    ...rest
  } = state;

  const isVerticalSplitView = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const isMapVisible = !preferences.isMapVisible;
  const rotateViewByOrientation = preferences.rotateViewByOrientation;
  const syncConfig = getSyncConfig();

  const userId = user.id;

  const client = useApolloClient();
  const conflictRef = useRef();
  const mapRef = useRef();
  const stateRef = useRef();
  const database = useRxDB();

  const enqueueLoadingSnackbar = useLoadingSnackbar();
  const [isFullScreen, setFullScreen] = useState(isMapVisible);
  const [createDispatchesMode] = useStickySessionState(CREATE_DISPATCHES_KEY);
  const isCreationMode = !!createDispatchesMode;
  const [isSyncing, setSyncing] = useState(false);
  const [title, setTitle] = useState();
  const [actions, setActions] = useState();

  const { classes } = useStyles();
  const syncCount = useSyncCount(database, syncConfig, userId);

  const shouldCheckForUpdates = useMemo(() => {
    return isOnline && !isSyncing && shouldPrefetch(userId, lastFetch, lastLogin);
  }, [isOnline, isSyncing, lastFetch, lastLogin, userId]);

  /**
   *  EVENT HANDLER
   */

  const getPreviousMap = useCallback(() => {
    const previousMap = mapRef.current?.map;

    return previousMap;
  }, []);

  const handleCloseSwipeableArea = () => {
    setFullScreen(false);
  };

  const handleClickSwipeableArea = () => {
    setFullScreen(!isFullScreen);
  };

  const handleSwipe = ({ dir }) => {
    setFullScreen(dir === 'Up');
  };

  const handleUpdateConflicts = () => {
    const execute = conflictRef.current?.onUpdateConflicts;

    execute && execute();
  };

  /**
   *  EVENT HANDLER SYNCHRONIZATION
   */

  const handleFetch = async () => {
    const prefixedKey = PREFERENCES_PREFIX + OFFLINE_DOCUMENT_SYNC_KEY;
    const syncDocumentsValue = getStickyValue(prefixedKey);
    const isSyncDocuments = syncDocumentsValue !== undefined ? syncDocumentsValue : true;

    const fetchConfig = getFetchConfig(mapProps, isSyncDocuments);

    setSyncing(true);

    const result = await enqueueLoadingSnackbar({
      loadingText: i18n.t('synchronization.notification.pull.loading'),
      finishedText: i18n.t('synchronization.notification.pull.loadingFinished'),
      func: () => pull({ database, client, config: fetchConfig, mapProps, userId }),
      onError: (error, enqueueSnackbar) => {
        enqueueSnackbar(error?.message, {
          preventDuplicate: true,
          variant: 'error',
        });
      },
    });

    const newLastFetch = {
      ...lastFetch,
      [userId]: +new Date(),
    };

    updateGlobalState({ lastFetch: newLastFetch });

    const conflicts = getConflicts(result);

    if (conflicts.length > 0) {
      showModal({
        title: i18n.t('synchronization.label.conflicts'),
        content: (
          <ConflictResolution
            config={fetchConfig}
            conflictRef={conflictRef}
            conflicts={conflicts}
            mapProps={mapProps}
          />
        ),
        isFullScreen: true,
        okAction: (
          <Trigger color="inherit" onClick={handleUpdateConflicts} size="medium" variant="text">
            {i18n.t('button.refresh')}
          </Trigger>
        ),
      });
    }

    setSyncing(false);
  };

  const handleSync = async () => {
    setSyncing(true);

    await enqueueLoadingSnackbar({
      loadingText: i18n.t('synchronization.notification.push.loading'),
      finishedText: i18n.t('synchronization.notification.push.loadingFinished'),
      func: () => push({ database, client, config: syncConfig, entityClasses, mapProps, userId }),
    });

    setSyncing(false);
  };

  /**
   *  EFFECTS
   */

  useEffect(() => {
    setFullScreen(isMapVisible);
  }, [isMapVisible]);

  /**
   *  CONTEXT
   */

  const appBarValue = useMemo(() => ({ title, setTitle, actions, setActions }), [actions, title]);

  /**
   *  APP STATE
   */

  const componentConfig = useMemo(() => config(), [config]);
  const { AppBarComponent, NavigationComponent, views } = componentConfig;
  const hasNavigation = views && views.length > 1;

  const vectorTileLayers = useMemo(() => {
    const overlayLayers = mapView?.overlayLayers || [];
    return overlayLayers.filter((layer) => layer.type === 'VectorTileLayer' && !!layer.sourceOptions?.url);
  }, [mapView]);

  const mapProps = useMemo(
    () => ({
      mapRef,
      markingColor: systemInformation?.spatial?.geometryColors?.marking,
      maxExtentZoomLevel: mapView?.maxExtentZoomLevel,
      primaryColor: systemInformation?.spatial?.geometryColors?.primary,
      rotateViewByOrientation,
      selectColor: systemInformation?.spatial?.geometryColors?.selection,
      srid,
      vectorTileLayers,
      vectorTileServerUrl,
      view: mapView,
      world,
    }),
    [mapView, rotateViewByOrientation, srid, systemInformation, vectorTileLayers, vectorTileServerUrl, world]
  );

  const appState = {
    ...rest,
    client,
    database,
    getPreviousMap,
    isLoading: isSyncing,
    mapProps,
    onCloseSwipeableArea: handleCloseSwipeableArea,
    onFetchData: handleFetch,
    onSyncData: handleSync,
    syncCount,
    systemInformation,
    updateGlobalState,
  };

  const swipeHandlers = useSwipeable({
    onSwiped: handleSwipe,
    trackMouse: true,
  });

  return (
    <AppBarProvider value={appBarValue}>
      <div className={classes.root}>
        {AppBarComponent && (
          <Fragment>
            <RouteContainer items={views.map((view) => ({ ...view, Component: AppBarComponent }))} state={appState} />
            <div className={classes.toolbar} />
          </Fragment>
        )}
        <div className={classes.container}>
          {hasNavigation && NavigationComponent && !isCreationMode && (
            <NavigationComponent items={views} state={appState} />
          )}
          <div ref={stateRef} />
          <div
            className={classNames(classes.content, {
              [classes.contentMobile]: hasNavigation && isMobile && !isCreationMode,
              [classes.contentSplitView]: isVerticalSplitView,
            })}
          >
            <Box
              sx={{
                ...styles.map,
                ...(isVerticalSplitView && styles.mapSplitView),
                ...(isCreationMode && styles.mapFullscreen),
                ...(isFullScreen && !isCreationMode && styles.mapHidden),
              }}
            >
              {isCreationMode && (
                <DispatchCreationMode
                  entityClasses={entityClasses}
                  mapProps={mapProps}
                  user={user}
                  {...createDispatchesMode}
                />
              )}
              <Map {...rest} {...mapProps} />
            </Box>
            <div
              className={classNames(classes.routes, {
                [classes.routesFullScreen]: isFullScreen && !isCreationMode,
                [classes.routesHidden]: isCreationMode,
                [classes.routesSplitView]: isVerticalSplitView,
              })}
            >
              {isVerticalSplitView && (
                <div className={classes.swipeableArea} onClick={handleClickSwipeableArea} {...swipeHandlers}>
                  <KeyboardArrowUpIcon className={classNames(classes.icon, { [classes.iconFlipped]: isFullScreen })} />
                </div>
              )}
              <RouteContainer items={views} state={{ ...appState, stateRef }} />
            </div>
          </div>
        </div>

        {shouldCheckForUpdates && (
          <PrefetchDataDialog client={client} database={database} handleFetch={handleFetch} userId={userId} />
        )}
      </div>
    </AppBarProvider>
  );
};

ViewContainer.propTypes = {
  config: PropTypes.func.isRequired,
  entityClasses: PropTypes.array,
  state: PropTypes.object,
};

export default ViewContainer;
