import getDefaultModifiedOn from '@database/getDefaultModifiedOn';

/**
 * Resets the document with an updated entity. Draft status and json patches are removed.
 * ModifiedOn is updated to newest value.
 * @param {object} doc as RxDocument
 * @param {object} updatedEntity
 * @param {Array.object} jsonPatch
 * @returns
 */

const resetDoc = async (doc, updatedEntity, jsonPatch) => {
  const resettedDoc = await doc.incrementalModify((oldData) => {
    oldData.entity = updatedEntity;
    oldData.draft = null;
    oldData.jsonPatch = jsonPatch ? jsonPatch : null;
    oldData.modifiedOn = getDefaultModifiedOn(updatedEntity);
    return oldData;
  });

  return resettedDoc;
};

export default resetDoc;
