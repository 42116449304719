import getExtendedEntity from './getExtendedEntity';

const updateDoc = async ({ additionalProperties = {}, doc, entity, mapProps, modifiedOn, remoteVersion, userId }) => {
  const extendedEntity = getExtendedEntity(entity, mapProps);

  const updatedDoc = await doc.incrementalModify((oldData) => {
    return {
      ...oldData,
      draft: null,
      entity: extendedEntity,
      jsonPatch: null,
      modifiedOn,
      remoteVersion,
      userId,
      ...additionalProperties,
    };
  });

  return updatedDoc;
};

export default updateDoc;
