import { addRxPlugin, createRxDatabase } from 'rxdb/plugins/core';
import { RxDBAttachmentsPlugin } from 'rxdb/plugins/attachments';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { RxDBMigrationSchemaPlugin } from 'rxdb/plugins/migration-schema';
import { wrappedValidateAjvStorage } from 'rxdb/plugins/validate-ajv';
import getPatchedEntity from './getPatchedEntity';

const patchPlugin = {
  rxdb: true,
  prototypes: {
    RxDocument: (proto) => {
      proto.getPatchedEntity = function () {
        return getPatchedEntity(this);
      };
    },
  },
};

addRxPlugin(RxDBMigrationSchemaPlugin);
addRxPlugin(patchPlugin);
if (process.env.NODE_ENV === 'development') {
  addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBAttachmentsPlugin);

const initialize = async (collections) => {
  const database = await createRxDatabase({
    name: 'offline_dexie_database',
    storage: wrappedValidateAjvStorage({
      storage: getRxStorageDexie(),
    }),
  });
  console.log('OFFLINE DATABASE: created database');

  if (collections) {
    await database.addCollections(collections);
    console.log(`OFFLINE DATABASE: added collection(s) ${Object.keys(collections).join(', ')}`);
  }

  return database;
};

export default initialize;
