/**
 * Remove json patchs from document for a specific path.
 * @param {object} doc as RxDocument
 * @param {string} pathToRemove
 * @returns
 */

const removePatchByPath = async (doc, pathToRemove) => {
  const updatedDoc = await doc.incrementalModify((oldData) => {
    const { jsonPatch = [] } = oldData;
    const newJsonPatch = jsonPatch.filter(({ path }) => path !== pathToRemove);
    oldData.jsonPatch = newJsonPatch.length === 0 ? null : newJsonPatch;
    return oldData;
  });

  return updatedDoc;
};

export default removePatchByPath;
